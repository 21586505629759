import { Fragment, useMemo, useEffect, useState, useCallback } from "react";
import { findUsedVendorsForDeclaration } from "@devowl-wp/cookie-consent-web-client";
import { useBanner, Checkbox, LinkToggle, CookieProperty, reactNl2Br, jsxJoin, useBannerStylesheet, generatePluralText, useDurationText, getTcfDataRetentionTimeForPurpose } from "../../../..";
import { extendCommonGroupsStylesheet, extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
const BannerTcfDeclaration = _ref => {
  let {
    declaration,
    id
  } = _ref;
  const {
    Link,
    Cookie
  } = useBannerStylesheet().extend(...extendCommonStylesheet).extend(...extendCommonGroupsStylesheet);
  const [isOpen, setIsOpen] = useState(false);
  const generateDurationText = useDurationText();
  const {
    tcf: {
      gvl,
      model
    },
    tcfFilterBy,
    group: {
      descriptionFontSize
    },
    activeAction,
    i18n: {
      purpose,
      tcf: {
        showMore,
        hideMore,
        example,
        vendors,
        vendorsCount,
        dataRetentionPeriod
      }
    },
    designVersion
  } = useBanner();
  const {
    [declaration]: {
      [id.toString()]: declarationObj
    }
  } = gvl;
  const {
    name,
    description,
    illustrations
  } = declarationObj;
  const isEssentialDeclaration = ["specialPurposes", "features", "dataCategories"].indexOf(declaration) > -1;
  const isDisabled = activeAction === "history" || isEssentialDeclaration;
  const checkedList = isEssentialDeclaration ? undefined : model[declaration === "specialFeatures" ? "specialFeatureOptins" : tcfFilterBy === "legInt" ? "purposeLegitimateInterests" : "purposeConsents"];

  // Save checked in own state cause the model does not trigger a re-render
  const modelIsChecked = isEssentialDeclaration || !!(checkedList !== null && checkedList !== void 0 && checkedList.has(id));
  const [isChecked, setIsChecked] = useState(modelIsChecked);

  // Update our checked state depending on the original value (e.g. switch of legInt | consent filter)
  useEffect(() => {
    setIsChecked(modelIsChecked);
  }, [modelIsChecked]);
  const handleCheckbox = useCallback(checked => {
    try {
      checkedList[checked ? "set" : "unset"](id);
      setIsChecked(checked);
    } catch (e) {
      // Silence is golden.
    }
  }, [id, checkedList, setIsChecked]);

  // Calculate all vendors using this declaration
  const useVendors = useMemo(() => findUsedVendorsForDeclaration(gvl, model, id, declaration, tcfFilterBy === "legInt"), [gvl, model, id, declaration, tcfFilterBy]);
  return h(Cookie, null, h(Checkbox, {
    hideCheckbox: ["purposes", "specialFeatures"].indexOf(declaration) === -1,
    isChecked: isChecked,
    isDisabled: isDisabled,
    fontSize: descriptionFontSize,
    onToggle: handleCheckbox,
    after: h(LinkToggle, {
      onToggle: setIsOpen,
      showMore: showMore,
      hideMore: hideMore
    })
  }, h("strong", null, name), designVersion > 6 && h(Fragment, null, " (", generatePluralText(useVendors.length, ...vendorsCount), ")")), isOpen && h(Fragment, null, h(CookieProperty, {
    label: purpose,
    value: reactNl2Br(description)
  }, (illustrations === null || illustrations === void 0 ? void 0 : illustrations.length) > 0 && illustrations.map((illustration, i) => h(CookieProperty, {
    key: illustration,
    label: `${example} #${i + 1}`,
    value: illustration
  }))), h(CookieProperty, {
    label: vendors,
    value: jsxJoin(useVendors.map(_ref2 => {
      let {
        id: vendorId,
        name,
        urls,
        dataRetention
      } = _ref2;
      return h(Link, {
        key: vendorId,
        href: (urls === null || urls === void 0 ? void 0 : urls[0].privacy) || "about:blank",
        target: "_blank",
        rel: "noreferrer"
      }, name, ["purposes", "specialPurposes"].indexOf(declaration) > -1 && dataRetention ? ` (${dataRetentionPeriod}: ${generateDurationText(getTcfDataRetentionTimeForPurpose(dataRetention, id, false), "d")})` : "");
    }), ", ")
  })));
};
export { BannerTcfDeclaration };