import { isPurposeEnabledForVendor } from ".";
/**
 * Get the function used to get vendors for a specific declaration.
 *
 * @see https://www.npmjs.com/package/@iabtechlabtcf/core#get-only-vendors-with-a-specific-feature-or-purpose-under-legal-basis
 */
function getGetterForGivenDeclaration(declaration, isLegInt) {
  switch (declaration) {
    case "features":
      return "getVendorsWithFeature";
    case "specialFeatures":
      return "getVendorsWithSpecialFeature";
    case "specialPurposes":
      return "getVendorsWithSpecialPurpose";
    case "purposes":
      return isLegInt ? "getVendorsWithLegIntPurpose" : "getVendorsWithConsentPurpose";
    case "dataCategories":
      return "getVendorsWithDataDeclarations";
    default:
      // This can never happen due to strictly typed parameters, but safely fall back
      return "getVendorsWithConsentPurpose";
  }
}

/**
 * Read vendors for a given declaration and also respect the publisher restrictions of a given model.
 */
function findUsedVendorsForDeclaration(gvl, model, id) {
  for (var _len = arguments.length, _ref = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    _ref[_key - 3] = arguments[_key];
  }
  let [declaration, isLegInt] = _ref;
  const getter = getGetterForGivenDeclaration(declaration, isLegInt);
  let vendors = getter === "getVendorsWithDataDeclarations" ?
  // This method is currently not implemented in the GVL object, so we need to implement hits ourself
  Object.values(gvl.vendors).filter(_ref2 => {
    let {
      dataDeclaration
    } = _ref2;
    return (dataDeclaration === null || dataDeclaration === void 0 ? void 0 : dataDeclaration.indexOf(id)) > -1;
  }).reduce((p, c) => {
    p[c.id] = c;
    return p;
  }, {}) : gvl[getter](id);
  if (["getVendorsWithLegIntPurpose", "getVendorsWithConsentPurpose"].indexOf(getter) > -1) {
    vendors = {
      ...vendors,
      ...gvl.getVendorsWithFlexiblePurpose(id)
    };
  }
  return Object.values(vendors).filter(vendor => {
    if (declaration === "purposes") {
      return isPurposeEnabledForVendor(model, id, isLegInt, vendor);
    }
    return true;
  })
  // We need to manually sort it again cause the `vendor-list.json#vendors` property uses numeric keys and
  // JavaScript does automatically sort it
  .sort((_ref3, _ref4) => {
    let {
      name: x
    } = _ref3;
    let {
      name: y
    } = _ref4;
    return x < y ? -1 : x > y ? 1 : 0;
  });
}
export { findUsedVendorsForDeclaration };