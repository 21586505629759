import { createTcfModelFromCookie, transformTcfAdTags } from ".";
import { BANNER_PRE_DECISION_SHOW_EVENT, OPT_IN_ALL_EVENT } from "../events";
/**
 * Dispatch events to the CMP API so vendors can listen to it.
 *
 * @see https://www.npmjs.com/package/@iabtechlabtcf/cmpapi#user-content-trigger-change-event
 */
function tcfCmpApiDispatcher(tcf, tcfMetadata, api, tcfCookieName) {
  const fnUpdateFromCurrentCookie = uiVisible => {
    const current = createTcfModelFromCookie(tcf, tcfMetadata, tcfCookieName);
    const encodedString = current === null || current === void 0 ? void 0 : current.tcfStringForVendors;
    if (uiVisible) {
      // If the UI is visible, we can have an encoded TCF string
      api.update(encodedString || "", true);
    } else if (!uiVisible) {
      if (encodedString) {
        api.update(encodedString, false);
      } else {
        // If the UI is not visible, and we do not have a TCF string, the GDPR does not apply for this request
        api.update(null);
      }
    }
    transformTcfAdTags(current === null || current === void 0 ? void 0 : current.tcfStringForVendors, current === null || current === void 0 ? void 0 : current.gvl);
  };

  // The UI got shown to the user
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, () => {
    fnUpdateFromCurrentCookie(true);
  });

  // The user has changed its privacy preferences
  document.addEventListener(OPT_IN_ALL_EVENT, () => {
    fnUpdateFromCurrentCookie(false);
  });
}
export { tcfCmpApiDispatcher };