import { BannerTcfDeclaration } from ".";
import { useBanner, useBannerTcfDeclarations } from "../../../..";
import { Group } from "../../../common";
const BannerTcfDeclarationList = _ref => {
  let {
    declaration
  } = _ref;
  const banner = useBanner();
  const {
    i18n: {
      tcf: {
        declarations: {
          [declaration]: {
            title,
            desc
          }
        }
      }
    }
  } = banner;
  const declarations = useBannerTcfDeclarations(declaration);
  return declarations.length > 0 ? h(Group, {
    headline: title
  }, desc, declarations.map(_ref2 => {
    let {
      id
    } = _ref2;
    return h(BannerTcfDeclaration, {
      key: id,
      declaration: declaration,
      id: id
    });
  })) : null;
};
export { BannerTcfDeclarationList };