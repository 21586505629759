import { Fragment } from "react";
import { useBanner, GroupButton, BannerTcfVendorList, BannerTcfDeclarationList, BANNER_GROUP_NON_STANDARD_STYLE } from "../../..";
import { Group, GroupList } from "../../common";
const DECLARATIONS = ["purposes", "specialPurposes", "features", "specialFeatures", "dataCategories"];
const BannerTcfGroupList = () => {
  const banner = useBanner();
  const {
    tcfFilterBy,
    i18n: {
      legitimateInterest,
      consent,
      tcf: {
        filterText,
        standard,
        standardDesc
      }
    },
    set
  } = banner;
  return h(Fragment, null, h(Group, {
    headline: standard,
    style: BANNER_GROUP_NON_STANDARD_STYLE,
    borderless: true
  }, standardDesc, h("div", {
    style: BANNER_GROUP_NON_STANDARD_STYLE
  }, h("span", {
    "aria-hidden": true
  }, filterText), "\xA0", h(GroupButton, {
    legend: filterText,
    active: tcfFilterBy,
    onChange: tcfFilterBy => set({
      tcfFilterBy: tcfFilterBy
    }),
    items: [{
      key: "legInt",
      value: legitimateInterest
    }, {
      key: "consent",
      value: consent
    }]
  }))), h(GroupList, null, h(BannerTcfVendorList, null), DECLARATIONS.map(declaration => h(BannerTcfDeclarationList, {
    key: declaration,
    declaration: declaration
  }))));
};
export { BannerTcfGroupList };