import { useEffect, useMemo, Fragment, useState, useCallback } from "react";
import { isPurposeEnabledForVendor } from "@devowl-wp/cookie-consent-web-client";
import { useBanner, Checkbox, LinkToggle, CookieProperty, useBannerStylesheet, calculateUnsafeCountries, useDurationText } from "../../../..";
import { extendCommonGroupsStylesheet } from "@devowl-wp/web-cookie-banner";
import { EServiceTemplateDataProcessingInCountriesSpecialTreatment } from "@devowl-wp/api-real-cookie-banner";
function getTcfDataRetentionTimeForPurpose(dataRetention, purposeId, isSpecial) {
  return dataRetention ? dataRetention[isSpecial ? "specialPurposes" : "purposes"][`${purposeId}`] || dataRetention.stdRetention : undefined;
}
const BannerTcfVendor = _ref => {
  var _deviceStorageDisclos;
  let {
    id
  } = _ref;
  const {
    Cookie
  } = useBannerStylesheet().extend(...extendCommonGroupsStylesheet);
  const [isOpen, setIsOpen] = useState(false);
  const banner = useBanner();
  const generateDurationText = useDurationText();
  const {
    isEPrivacyUSA,
    isDataProcessingInUnsafeCountries,
    dataProcessingInUnsafeCountriesSafeCountries,
    iso3166OneAlpha2,
    designVersion,
    tcfFilterBy,
    tcf: {
      gvl,
      model,
      original: {
        vendorConfigurations
      }
    }
  } = banner;
  const {
    vendors: {
      [id]: vendor
    },
    purposes,
    specialPurposes,
    features,
    specialFeatures,
    dataCategories
  } = gvl;
  const {
    name,
    urls,
    [tcfFilterBy === "consent" ? "purposes" : "legIntPurposes"]: usedPurposes,
    flexiblePurposes,
    specialPurposes: usedSpecialPurposes,
    features: usedFeatures,
    specialFeatures: usedSpecialFeatures,
    dataDeclaration,
    usesCookies,
    cookieMaxAgeSeconds,
    cookieRefresh,
    usesNonCookieAccess,
    dataRetention,
    ...restVendor
  } = vendor;
  const {
    ePrivacyUSA,
    dataProcessingInCountries,
    dataProcessingInCountriesSpecialTreatments
  } = useMemo(() => Object.values(vendorConfigurations).filter(_ref2 => {
    let {
      vendorId
    } = _ref2;
    return vendorId === id;
  })[0], [id]);
  const filteredPurposes = useMemo(() => [...usedPurposes,
  // Add flexible purposes which are not yet stored in used purposes (unique)
  ...flexiblePurposes.filter(flexiblePurposeId => usedPurposes.indexOf(flexiblePurposeId) === -1)].filter(purposeId => isPurposeEnabledForVendor(model, purposeId, tcfFilterBy === "legInt", vendor)), [id, tcfFilterBy]);
  const {
    group: {
      descriptionFontSize
    },
    i18n: {
      tcf: {
        declarations: i18nTcfDeclarations,
        dataRetentionPeriod,
        ...i18nTcf
      },
      ...i18n
    },
    activeAction
  } = banner;

  // Get device storage disclosure (need to type to any cause the original GVL does not provide this information)
  const deviceStorageDisclosure = restVendor.deviceStorageDisclosure;
  const additionalInformation = restVendor.additionalInformation;
  const provider = additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.legalAddress;
  const internationalTransfers = !!(additionalInformation !== null && additionalInformation !== void 0 && additionalInformation.internationalTransfers);
  const transferMechanisms = (additionalInformation === null || additionalInformation === void 0 ? void 0 : additionalInformation.transferMechanisms) || [];
  const checkedList = model[tcfFilterBy === "consent" ? "vendorConsents" : "vendorLegitimateInterests"];
  const isDisabled = activeAction === "history";

  // Save checked in own state cause the model does not trigger a re-render
  const modelIsChecked = checkedList.has(id);
  const [isChecked, setIsChecked] = useState(modelIsChecked);

  // Update our checked state depending on the original value (e.g. switch of legInt | consent filter)
  useEffect(() => {
    setIsChecked(modelIsChecked);
  }, [modelIsChecked]);
  const handleCheckbox = useCallback(checked => {
    try {
      checkedList[checked ? "set" : "unset"](id);
      setIsChecked(checked);
    } catch (e) {
      // Silence is golden.
    }
  }, [id, checkedList, setIsChecked]);
  const dataProcessingInUnsafeCountries = useMemo(() => {
    const mapCountryIso = c => iso3166OneAlpha2[c] || c;
    return isDataProcessingInUnsafeCountries ? calculateUnsafeCountries({
      dataProcessingInCountries,
      safeCountries: dataProcessingInUnsafeCountriesSafeCountries,
      specialTreatments: dataProcessingInCountriesSpecialTreatments.filter(e => [
      // Ignore the following special treatments for displaying unsecuring countries
      EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses].indexOf(e) === -1),
      designVersion
    }).map(mapCountryIso) : [];
  }, [isDataProcessingInUnsafeCountries, dataProcessingInUnsafeCountriesSafeCountries, dataProcessingInCountriesSpecialTreatments, dataProcessingInCountries, iso3166OneAlpha2, designVersion]);
  const appropriateSafeguards = useMemo(() => [...new Set(
  // Remove duplicates
  [designVersion > 4 && dataProcessingInCountriesSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses) > -1 && i18n.standardContractualClauses, designVersion > 6 && internationalTransfers && transferMechanisms.map(mechanism => {
    switch (mechanism) {
      case "SCCs":
        return i18n.standardContractualClauses;
      case "Adequacy decision":
        return i18n.adequacyDecision;
      case "BCRs":
        return i18n.bindingCorporateRules;
      case "Other":
        return i18n.other;
      default:
        return "";
    }
  })].flat().filter(Boolean))], [designVersion, dataProcessingInCountriesSpecialTreatments]);
  const {
    privacy,
    legIntClaim
  } = (urls === null || urls === void 0 ? void 0 : urls[0]) || {
    langId: "",
    privacy: "",
    legIntClaim: ""
  };
  const listPurposes = useCallback(function (purposeIds) {
    let isSpecial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    return purposeIds.map(declarationId => `${(isSpecial ? specialPurposes : purposes)[declarationId].name}${dataRetention ? ` (${dataRetentionPeriod}: ${generateDurationText(getTcfDataRetentionTimeForPurpose(dataRetention, declarationId, false), "d")})` : ""}`).join(", ");
  }, [purposes, specialPurposes, dataRetention]);
  return h(Cookie, null, h(Checkbox, {
    isChecked: isChecked,
    isDisabled: isDisabled,
    fontSize: descriptionFontSize,
    onToggle: handleCheckbox,
    after: h(LinkToggle, {
      onToggle: setIsOpen,
      showMore: i18nTcf.showMore,
      hideMore: i18nTcf.hideMore
    })
  }, h("strong", null, name)), isOpen && h(Fragment, null, !!provider && h(CookieProperty, {
    label: i18n.provider,
    value: provider.split(";").join(", ")
  }), h(CookieProperty, {
    label: i18n.providerPrivacyPolicyUrl,
    value: privacy
  }), tcfFilterBy === "legInt" && h(CookieProperty, {
    label: i18nTcf.legIntClaim,
    value: legIntClaim
  }), !!isEPrivacyUSA && h(CookieProperty, {
    label: i18n.ePrivacyUSA,
    value: internationalTransfers ? i18n.yes : ePrivacyUSA === 2 ? i18n.unknown : ePrivacyUSA === 1 ? i18n.yes : i18n.no
  }), dataProcessingInUnsafeCountries.length > 0 && h(CookieProperty, {
    label: i18n.dataProcessingInUnsafeCountries,
    value: dataProcessingInUnsafeCountries.join(", ")
  }), appropriateSafeguards.length > 0 && h(CookieProperty, {
    label: i18n.appropriateSafeguard,
    value: appropriateSafeguards.join(", ")
  }), filteredPurposes.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.purposes.title,
    value: listPurposes(filteredPurposes)
  }), usedSpecialPurposes.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.specialPurposes.title,
    value: listPurposes(usedSpecialPurposes, true)
  }), usedFeatures.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.features.title,
    value: usedFeatures.map(declarationId => features[declarationId].name).join(", ")
  }), usedSpecialFeatures.length > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.specialFeatures.title,
    value: usedSpecialFeatures.map(declarationId => specialFeatures[declarationId].name).join(", ")
  }), (dataDeclaration === null || dataDeclaration === void 0 ? void 0 : dataDeclaration.length) > 0 && h(CookieProperty, {
    label: i18nTcfDeclarations.dataCategories.title,
    value: dataDeclaration.map(declarationId => dataCategories[declarationId].name).join(", ")
  }), h(CookieProperty, {
    label: i18n.usesCookies,
    value: usesCookies,
    printValueAs: "boolean"
  }), usesCookies && h(CookieProperty, {
    label: i18n.duration,
    value: cookieMaxAgeSeconds <= 0 ? "Session" : generateDurationText(cookieMaxAgeSeconds, "s")
  }), h(CookieProperty, {
    label: i18n.cookieRefresh,
    value: cookieRefresh,
    printValueAs: "boolean"
  }), h(CookieProperty, {
    label: i18n.usesNonCookieAccess,
    value: usesNonCookieAccess,
    printValueAs: "boolean"
  }), deviceStorageDisclosure === null || deviceStorageDisclosure === void 0 ? void 0 : (_deviceStorageDisclos = deviceStorageDisclosure.disclosures) === null || _deviceStorageDisclos === void 0 ? void 0 : _deviceStorageDisclos.map(_ref3 => {
    let {
      type,
      identifier,
      domain,
      domains,
      maxAgeSeconds,
      cookieRefresh,
      purposes: disclosurePurposes
    } = _ref3;
    return h(CookieProperty, {
      key: name,
      label: i18n.technicalCookieDefinition,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, identifier)
    }, h(CookieProperty, {
      label: i18n.type,
      value: getTcfCookieTypeLocalization(type)
    }), !!domains && h(CookieProperty, {
      label: i18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, domains.join(","))
    }) || !!domain && h(CookieProperty, {
      label: i18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, domain)
    }), maxAgeSeconds !== null && h(CookieProperty, {
      label: i18n.duration,
      value: maxAgeSeconds <= 0 ? "Session" : generateDurationText(maxAgeSeconds, "s")
    }), h(CookieProperty, {
      label: i18n.cookieRefresh,
      value: cookieRefresh,
      printValueAs: "boolean"
    }), !!(disclosurePurposes !== null && disclosurePurposes !== void 0 && disclosurePurposes.length) && h(CookieProperty, {
      label: i18nTcfDeclarations.purposes.title,
      value: disclosurePurposes.map(purposeId => {
        var _purposes$purposeId;
        return (_purposes$purposeId = purposes[purposeId]) === null || _purposes$purposeId === void 0 ? void 0 : _purposes$purposeId.name;
      }).filter(Boolean).join(", ")
    }));
  })));
};
function getTcfCookieTypeLocalization(type) {
  switch (type) {
    case "cookie":
      return "HTTP Cookie";
    case "web":
      return "LocalStorage, Session Storage, IndexDB";
    case "app":
      return "App";
    default:
      return type;
  }
}
export { BannerTcfVendor, getTcfCookieTypeLocalization, getTcfDataRetentionTimeForPurpose };