import { BannerTcfVendor } from ".";
import { useBanner, useTcfVendors } from "../../../..";
import { Group } from "../../../common";
const BannerTcfVendorList = () => {
  const banner = useBanner();
  const {
    tcfFilterBy,
    i18n: {
      tcf: {
        vendors,
        filterNoVendors
      }
    }
  } = banner;
  const useVendors = useTcfVendors(tcfFilterBy);
  const hasVendors = useVendors.length > 0;
  return h(Group, {
    headline: vendors
  }, useVendors.map(_ref => {
    let {
      id
    } = _ref;
    return h(BannerTcfVendor, {
      key: id,
      id: id
    });
  }), !hasVendors && filterNoVendors);
};
export { BannerTcfVendorList };