import { useMemo } from "react";
import { useBanner } from "../../contexts";
function useTcfVendors(filter) {
  const {
    tcf
  } = useBanner();
  if (!tcf) {
    return [];
  }
  const {
    gvl: {
      vendors: gvlVendors
    },
    model
  } = tcf;
  return useMemo(() => Object.values(gvlVendors)
  // Only show vendor if suitable for the current filter
  .filter(_ref => {
    let {
      [filter === "consent" ? "purposes" : "legIntPurposes"]: usedPurposes
    } = _ref;
    return filter === "consent" ? true : usedPurposes.length > 0;
  })
  // We need to manually sort it again cause the `vendor-list.json#vendors` property uses numeric keys and
  // JavaScript does automatically sort it
  .sort((_ref2, _ref3) => {
    let {
      name: x
    } = _ref2;
    let {
      name: y
    } = _ref3;
    return x < y ? -1 : x > y ? 1 : 0;
  }), [gvlVendors, filter, model]);
}
export { useTcfVendors };