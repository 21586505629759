import { findUsedVendorsForDeclaration } from ".";

/**
 * Get a list of used declarations for e.g. `purposes`. It can also return an
 * empty array if the given declaration does not have any items/vendors.
 */
function findUsedDeclarations(declaration, gvl, model, filterBy) {
  const {
    [declaration]: declarations
  } = gvl;
  const declarationValues = Object.values(declarations);

  // Collect a map of `id` => `length of vendors` so we can hide the complete declaration if no vendor is available
  const lengthMap = declarationValues.reduce((map, _ref) => {
    let {
      id
    } = _ref;
    map[id] = findUsedVendorsForDeclaration(gvl, model, id, declaration, filterBy === "legInt").length;
    return map;
  }, {});
  return declarationValues.filter(_ref2 => {
    let {
      id
    } = _ref2;
    return lengthMap[id];
  });
}
export { findUsedDeclarations };