import { TCString } from "@iabtechlabtcf/core";
import { createTcfModel } from ".";
import Cookie from "js-cookie";
/**
 * Get the current TCF string stored in our first party cookie and return a valid GVL instance and TCModel.
 * It returns `undefined` if no TCF string is currently present.
 */
function createTcfModelFromCookie(tcf, tcfMetadata, cookieName) {
  const cookieValue = Cookie.get(cookieName);
  if (!cookieValue) {
    return undefined;
  }
  const {
    gvl,
    model
  } = createTcfModel({
    tcf,
    tcfMeta: tcfMetadata,
    tcfString: cookieValue
  });
  return {
    gvl,
    model,
    tcfString: cookieValue,
    tcfStringForVendors: TCString.encode(model, {
      isForVendors: true
    })
  };
}
export { createTcfModelFromCookie };