import { useMemo } from "react";
import { findUsedDeclarations } from "@devowl-wp/cookie-consent-web-client";
import { useBanner } from "../../../..";
import { AccordionItem, AccordionList } from "../../common";
import { CaretDownFilled, CaretRightFilled, DownOutlined, RightOutlined } from "@ant-design/icons-svg";
const BannerTcfStacks = () => {
  const banner = useBanner();
  const {
    tcf: {
      gvl,
      model
    },
    texts: {
      tcfStacksCustomName,
      tcfStacksCustomDescription
    },
    bodyDesign: {
      accordionArrowType
    },
    isListServicesNotice
  } = banner;
  const {
    vendors,
    stacks,
    purposes: {
      1: purpose1
    }
  } = gvl;

  // Always show Purpose #1 in initial layer, but first check if it is generally in use
  const usesPurpose1 = useMemo(() => {
    const legInt = findUsedDeclarations("purposes", gvl, model, "legInt").filter(_ref => {
      let {
        id
      } = _ref;
      return id === 1;
    });
    const consent = findUsedDeclarations("purposes", gvl, model, "consent").filter(_ref2 => {
      let {
        id
      } = _ref2;
      return id === 1;
    });
    return legInt.length > 0 || consent.length > 0;
  }, [vendors, gvl, model]);
  const icon = accordionArrowType !== "none" ? accordionArrowType === "filled" ? CaretRightFilled : RightOutlined : undefined;
  const iconExpanded = accordionArrowType !== "none" ? accordionArrowType === "filled" ? CaretDownFilled : DownOutlined : undefined;
  return h(AccordionList, null, usesPurpose1 && h(AccordionItem, {
    title: purpose1.name,
    icon: icon,
    iconExpanded: iconExpanded
  }, purpose1.description), Object.values(stacks).map(_ref3 => {
    let {
      id,
      name,
      description
    } = _ref3;
    return h(AccordionItem, {
      key: id,
      title: name,
      icon: icon,
      iconExpanded: iconExpanded
    }, description);
  }), !isListServicesNotice && h(AccordionItem, {
    title: tcfStacksCustomName,
    icon: icon,
    iconExpanded: iconExpanded
  }, tcfStacksCustomDescription));
};
export { BannerTcfStacks };