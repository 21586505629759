import { PurposeRestriction, RestrictionType } from "@iabtechlabtcf/core";
/**
 * Apply publisher restrictions to a given `TCModel`.
 *
 * @see https://www.npmjs.com/package/@iabtechlabtcf/core#user-content-setting-publisher-restrictions
 */
function applyRestrictivePurposes(model, vendorConfigurations) {
  if (!model.isServiceSpecific) {
    return; // must only be saved to a service-specific TC String
  }

  Object.values(vendorConfigurations).forEach(_ref => {
    let {
      vendorId,
      restrictivePurposes
    } = _ref;
    for (const purposeId in restrictivePurposes["normal"]) {
      const {
        enabled,
        legInt
      } = restrictivePurposes["normal"][purposeId];
      const purposeRestriction = new PurposeRestriction();
      purposeRestriction.purposeId = +purposeId;
      if (!enabled) {
        purposeRestriction.restrictionType = RestrictionType.NOT_ALLOWED;
      } else if (legInt) {
        purposeRestriction.restrictionType = legInt === "yes" ? RestrictionType.REQUIRE_LI : RestrictionType.REQUIRE_CONSENT;
      }
      if (purposeRestriction.restrictionType !== undefined) {
        model.publisherRestrictions.add(vendorId, purposeRestriction);
      }
    }
  });
}
export { applyRestrictivePurposes };