import { findUsedDeclarations } from "@devowl-wp/cookie-consent-web-client";
import { useBanner } from "../..";
import { useMemo } from "react";

/**
 * Get a list of used declarations for e.g. `purposes`. It can also return an
 * empty array if the given declaration does not have any items.
 */
function useBannerTcfDeclarations(declaration) {
  const banner = useBanner();
  const {
    tcf: {
      gvl,
      model
    },
    tcfFilterBy
  } = banner;
  const {
    [declaration]: declarations
  } = gvl;
  return useMemo(() => {
    return findUsedDeclarations(declaration, gvl, model, tcfFilterBy);
  }, [declarations, gvl, model, declaration, tcfFilterBy]);
}
export { useBannerTcfDeclarations };