import { CookieConsentManager } from "..";
/**
 * Apply our meta data fields to the `TCModel`.
 *
 * @see https://www.npmjs.com/package/@iabtechlabtcf/core#user-content-cmp-meta-fields
 */
function applyTcfModelMetadata(model, tcfMeta, options) {
  const {
    cmpVersion,
    cmpId
  } = options instanceof CookieConsentManager ? options.getOptions() : options;
  model.cmpId = cmpId;
  model.isServiceSpecific = tcfMeta.scope === "service-specific";
  model.cmpVersion = cmpVersion;
  // model.consentLanguage = tcfMeta["language"].toUpperCase(); (see https://git.io/Jmjqs)
}

export { applyTcfModelMetadata };