import { extendCommonStylesheet } from "@devowl-wp/web-cookie-banner";
import { useStylesheet } from "../../contexts";
import { useA11yId } from "../../hooks";
const GroupButton = _ref => {
  let {
    legend,
    active,
    onChange,
    items
  } = _ref;
  const {
    ButtonGroup,
    buttonGroupItem,
    screenReaderOnlyClass,
    Label
  } = useStylesheet().extend(...extendCommonStylesheet);
  const a11yName = useA11yId();
  const handleChange = e => {
    const {
      value
    } = e.target;
    onChange(value);
  };
  return h(ButtonGroup, null, h("legend", {
    className: screenReaderOnlyClass
  }, legend), items.map(_ref2 => {
    let {
      key,
      value
    } = _ref2;
    return h(Label, {
      key: key
    }, h("input", {
      name: a11yName,
      type: "radio",
      value: key,
      checked: active === key,
      className: buttonGroupItem,
      "aria-label": value,
      onChange: handleChange
    }), h("span", {
      "aria-hidden": true
    }, value));
  }));
};
export { GroupButton };